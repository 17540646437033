import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import { ReactComponent as IconClose } from '../../assets/exports/close.svg'

const Container = styled.div`
    padding: 4px;

    position: absolute;
    width: 32px;
    height: 32px;
    right: 16px;
    top: 16px;

    background: #E6F5EF;
    border-radius: 100px;

    cursor: pointer;
`

export const Close = ({action, className}) => (
    <Container className={className} onClick={action}>
        <IconClose />
    </Container>
)

Close.propTypes = {
	action: PropTypes.func.isRequired,
    className: PropTypes.string,
}
