import PropTypes from 'prop-types'
import { useUi } from '../../context/UIContext'
import { MenuItem, SubMenu } from 'react-pro-sidebar'
import { Link } from 'react-router-dom'
import SidebarMenu from './SidebarMenu'
import circle from '../../assets/exports/Side/Bottom/Circle.svg'

const Item = ({ id, path, icon, label, active, activate, isSubmenuItem}) => {
    const { toggleSidebar } = useUi()

    const handleClick = () => {
        activate(id)
        toggleSidebar()
    }

    return (
        <MenuItem
            active={id === active}
            rootStyles={{}}
            component={<Link to={path} />}
            icon={<img src={isSubmenuItem ? circle : icon} alt='icon' />}
            onClick={handleClick}
        >
            {label}
        </MenuItem>     
    )
}

const Dropdown = ({ id, suffix, icon, label, children, active, open, expand }) => (
    <SubMenu
        open={id === open}
        active={id === active}
        suffix={suffix}
        label={label}
        icon={<img src={icon} alt='icon' />}
        rootStyles={{}}
        onClick={() => expand(id)}
    >
        <SidebarMenu items={children} isSubmenu={true} />
    </SubMenu>         
)

const NavItem = (props) => props.children ? <Dropdown {...props} /> : <Item {...props} />

NavItem.defaultProps = {
    path: '/',
    icon: 'circle',
}

NavItem.propTypes = {
    id: PropTypes.number.isRequired,
    path: PropTypes.string,
    icon: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.array
    ]),
    label: PropTypes.string.isRequired,
    badge: PropTypes.exact({
        bgColor: PropTypes.string,
        text: PropTypes.any
    }),
    header: PropTypes.bool,
    children: PropTypes.array,
    active: PropTypes.number,
    activate: PropTypes.func.isRequired,
    open: PropTypes.number,
    expand: PropTypes.func.isRequired,
}

export default NavItem
