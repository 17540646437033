import axios from "axios"

function errorResponse(response) {
    switch (response.status) {
        case 500:
            return 'Server Error'

        case 422:
            const data = response.data
            let msg = (typeof(data) === 'object') ? data.message : data

            if (data.errors && data.errors[0]) {
                const { property, value, message } = data.errors[0]
               
                msg = message.replace('value', `${property} (${value})`)
            }
            
            return msg

        case 400:
            return (typeof(response.data) === 'object') ? response.data.message : response.data
            
        default:
            return (typeof(response.data) === 'object') ? response.data.message : response.data
    }
}

/**
 * Prepare error message for user friendly output
 * 
 * @param {object} error 
 */
export const errorMessage = error => {
    let msg

    if (axios.isCancel(error)) {
        msg = 'Operation canceled'
    }

    if (!error.response) {
        msg = error.message
    }
    
    if (error?.response) {
        if (error.response.status === 0) {
            msg = error.message
        } else if ( error.response ) {
            msg = errorResponse(error.response)
        } else {
            msg = error.toString()
        } 
    }

    return msg
}