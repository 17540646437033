import { useAuth } from '../../context'
import { Account, Column, Dropdown, DropdownHeader, DropdownItem, Icon } from './styles/Header'
import { Text } from '../Common/Text'
import { ReactComponent as Avatar } from '../../assets/exports/Users/account.svg'
import { ReactComponent as IconSettings } from '../../assets/exports/Side/Bottom/settings/Default.svg'
import { ReactComponent as IconTeam } from '../../assets/exports/Side/Bottom/Team.svg'
import { ReactComponent as IconBilling } from '../../assets/exports/Side/Bottom/credit_card.svg'
import { ReactComponent as IconLogout } from '../../assets/exports/logout.svg'
import { Col, Container, Row } from 'react-grid-system'
import { useUi } from '../../context/UIContext'
import { useGlobal } from '../../context/GlobalDataContext'
import { Picture } from '../Common/Picture'
import { Subscription } from './Subscription'
import { Notification } from './Notification'
import { ReactComponent as IconBars } from '../../assets/exports/bars.svg'

const Header = () => {
    const {signOut} = useAuth()
    const { broken, toggleSidebar } = useUi()
    const {profile: {email, firstname, lastname, image}, tenant} = useGlobal().data

    const handleSignout = e => {
        e.preventDefault()
        signOut()
    }

    const profileImg = image
        ? <Account><Picture image={image} css={{width: '100%', height: '100%'}} /></Account>
        : <Account><Avatar width={22} height={22} /></Account>

    return (
        <header id='MainHeader' style={{borderBottom: '1px solid #EBEBEB'}}>
            <Container fluid>
                <Row>
                    <Col style={{display: 'flex', alignItems: 'center'}}>
                        {broken && <IconBars css={{cursor: 'pointer'}} onClick={toggleSidebar} />}
                    </Col>

                    <Col xs={9} component={Column}>
                        <Subscription subscription={tenant.subscription} />

                        <Notification />

                        <Dropdown
                            trigger={profileImg}
                            header={
                                <DropdownHeader>
                                    {profileImg}

                                    <div className='group'>
                                        <Text size='18px' weight={600}>{firstname} {lastname}</Text>
                                        <Text size='14px' weight={500}>{email}</Text>
                                    </div>
                                </DropdownHeader>                            
                            }
                            items={[
                                <DropdownItem to='/settings'>
                                    <Icon><IconSettings /></Icon>
                                    <Text>Settings</Text>
                                </DropdownItem>,

                                <DropdownItem to='/billing'>
                                    <Icon><IconBilling /></Icon>
                                    <Text>Billing</Text>
                                </DropdownItem>,

                                <DropdownItem to='/team'>
                                    <Icon><IconTeam /></Icon>
                                    <Text>Team</Text>
                                </DropdownItem>,    

                                <DropdownItem onClick={handleSignout}>
                                    <Icon><IconLogout /></Icon>
                                    <Text>Logout</Text>
                                </DropdownItem>,               
                            ]}
                        />   
                    </Col>  
                </Row>
            </Container>                                        
        </header>
    )
}

export default Header
