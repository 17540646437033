import styled from '@emotion/styled'
import { Link } from 'react-router-dom'

export const Text = styled.p`
    font-family: 'Inter';
    font-style: normal;
    font-weight: ${props => props.weight || 400 };
    font-size: ${props => props.size || '16px' };
    text-align: ${props => props.align || 'left' };
    
    color: ${props => props.color || '#193B40' };
`

export const FormTitle = styled(Text)`
    font-weight: 500;
    font-size: 28px;
`
export const FormDesc = styled(Text)`
    color: #647F87;
`

export const TextLink = Text.withComponent(Link)

export const Color = styled.span`
    color: ${props => props.color};
`