export class ContractPeriod {
    static Year = new ContractPeriod('12 Months')
    static Month = new ContractPeriod('1 Month')
    static Week = new ContractPeriod('1 Week')
    static Custom = new ContractPeriod('Custom')

    constructor(value) {
        this.value = value
    }
}
