export class Routes {
    static Dashboard = new Routes('')
    static House = new Routes('houses')
    static Land = new Routes('land')
    static CommercialBuilding = new Routes('commercials')
    static Auditorium = new Routes('auditoriums')
    static Contract = new Routes('contracts')
    static Client = new Routes('clients')
    static Communication = new Routes('communication')
    static Maintenance = new Routes('maintenance')
    static Team = new Routes('team')
    static Billing = new Routes('billing')
    static Settings = new Routes('settings')
    static ActivateAccount = new Routes('activate_user')
    static Login = new Routes('login')

    constructor(value) {
        this.value = value
    }
}
