import './index.scss'
import React, { lazy } from 'react'
import ReactDOM from 'react-dom/client'
import reportWebVitals from './reportWebVitals'
import { ErrorBoundary } from './components/Common'
import { BrowserRouter as Router } from 'react-router-dom'
import { Provider as AlertProvider } from '@blaumaus/react-alert'
import { AuthProvider } from './context'
import alert from './config/alert'
import { app } from './config'

const App = lazy(() => import('./components/App'))
const Site = lazy(() => import('./components/Site'))

const root = ReactDOM.createRoot(document.getElementById('root'))

root.render(
	// <React.StrictMode>
			<ErrorBoundary>
				<Router>
					{(app.fqdn.split('.')[0] !== app.subdomain)
						? <Site />
						: <AlertProvider template={alert.template} {...alert.options}>
							<AuthProvider>
								<App />
							</AuthProvider>
						</AlertProvider>
					}
				</Router>
			</ErrorBoundary>
	// </React.StrictMode>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
