import { storage } from "../utils/storage"

const app = {
    api: window._env_.PROXY_API || 'http://localhost:8080',
    jwt: storage.getUser()?.accessToken,
    name: process.env.REACT_APP_NAME || 'RealtySupport',
    fqdn: window.location.host.replace('www.', ''),
    subdomain: 'app',
    tax: 7.5,
}

export const sysMsgFlag = 'system_message_close_by_user'

export default app
