import { cloneElement, useState } from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import { useOutsideClick } from '../../hooks/useOutsideClick'

const Container = styled.div`
    position: relative;
    z-index: 1;
`

const Drop = styled.div`
    position: absolute;
    width: 200px;
    
    border: 1px solid whitesmoke;
    border-radius: 6px;

    overflow: hidden;
`

const Menu = styled.ul`
    display: flex;
    flex-direction: column;
    padding: 12px;
    gap: 8px;

    width: 100%;

    background: #FFFFFF;
`

const Item = styled.li`
    width: 100%;
`

const Dropdown = ({ trigger, header, items, children, className }) => {
    const [open, setOpen] = useState(false)
    
    const handleOpen = () => {
        // e.currentTarget.parentNode
        ref.current.style.zIndex = open ? 1 : 2
        setOpen(!open)
    }

    const handleClickOutside = () => {
        ref.current.style.zIndex = 1
        setOpen(false)
    }

    const ref = useOutsideClick(handleClickOutside)

    return (
        <Container ref={ref} className={className} onClick={(e) => e.stopPropagation()}>
            {cloneElement(trigger, {
                style: {cursor: 'pointer'},
                onClick: (e) => {
                    handleOpen()

                    if (trigger.props.onClick) {
                        trigger.props.onClick(e)
                    }
                    
                    e.preventDefault()
                },
            })}

            { open &&
                <Drop className='dropdown'>
                    {header && header}

                    {items && (
                        <Menu>
                            { items.map( (item, index) => <Item key={index}>
                                {cloneElement(item, {
                                    onClick: (e) => {
                                        if (item.props.onClick) {
                                            item.props.onClick(e)
                                        }

                                        setOpen(false)
                                        e.stopPropagation()
                                    }
                                })}
                            </Item> ) }                        
                        </Menu>
                    )}

                    {children}
                </Drop>            
            }
        </Container>
    )
}

Dropdown.propTypes = {
	trigger: PropTypes.object.isRequired,
    header: PropTypes.object,
	items: PropTypes.array,
    children: PropTypes.node,
    className: PropTypes.string,
}

export default Dropdown