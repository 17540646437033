export const parseJwt = (token) => {
    try {
        return JSON.parse(atob(token.split('.')[1]))
    } catch (e) {
        return null
    }
}

export function basename(path) {
    return path.split(/[\\/]/).pop()
}

export function dataURLtoFile(dataurl, filename) {
    let arr = dataurl.split(','),
        mime = arr[0].match(/:(.*?);/)[1],
        bstr = atob(arr[1]),
        n = bstr.length,
        u8arr = new Uint8Array(n)

    while(n--){
        u8arr[n] = bstr.charCodeAt(n)
    }

    return new File([u8arr], filename, {type: mime})

    // blob: new Blob([u8arr], {type: mime})
}

export function initials(name) {
    let parts = name.split(' '), initials = ''

    for (let i = 0; i < parts.length; i++) {
        if (parts[i].length > 0 && parts[i] !== '') {
            initials += parts[i][0]
        }
    }

    return initials.toUpperCase()
}
