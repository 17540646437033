
export function env(key, def) {
    const val = window._env_[key]

    if (val === true || val === 'true') {
        return true
    }

    if (val === false || val === 'false') {
        return false
    }

    return val || def
}
