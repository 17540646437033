import styled from '@emotion/styled'
import { Text } from '../../Common/Text'
import Button from '../../Common/Button/Button'
import { layout } from '../../../config/styles'

const { headerHeight, footerHeight } = layout.sidebar

export const Header = styled.header`
    display: flex;
    justify-content: center;
    height: ${headerHeight};

    ${Text} {
        color: #8EB3BD !important;
    }
`

export const MenuBox = styled.div`
    display: flex;
    flex-direction: column;
    padding: 24px 32px;
    gap: 52px;

    height: calc(100% - ${headerHeight} - ${footerHeight});
    overflow-y: auto;
`

export const MenuSection = styled.section`
    display: flex;
    flex-direction: column;
    gap: 27px;
`

export const StyledText = styled(Text)`
    font-weight: 500;
    font-size: 12px;
    line-height: 14px;
    text-transform: uppercase;
    color: #8EB3BD;
`

export const Main = styled.main`
    display: flex;
    width: 100%;
    flex-direction: column;
    overflow: hidden;
`

export const ToggleBtn = styled(Button)`
    position: absolute;
    top: 20px;
`

export const Footer = styled.footer`
    display: flex;
    height: ${footerHeight};
    justify-content: center;
    align-items: center;
    border-top: 1px solid #193B40;
`

export const Resizer = styled.div`
    display: flex;
    width: 24px;
    height: 24px;
    justify-content: center;
    align-items: center;
    background: #8EB3BD;
    border-radius: 100px;
    cursor: pointer;

    svg {
        path {
            fill: #0B2B33;
        }
    }
`

export const Container = styled.div`
    display: flex;
    height: 100%;

    .ps-sidebar-root {
        border: 0px solid #EBEBEB;

        &.ps-collapsed {
            ${MenuBox} {
                padding: 0 20px;
            }

            .brand-text, .ps-menu-label, ${StyledText} {
                display: none;
            }
        }
    }
`