import { createContext, useContext, useState } from 'react'
import { md } from '../config/breakpoints'

/**
 * Context provider for user interface.
 *
 */
const UiContext = createContext()

const config = {
    pageTitle: 'Home',

    collapsed: false,
    toggled: false,
    broken: window.matchMedia(`(max-width: ${md}px)`).matches,
    mouseEnter: false,

	sidebarTheme: 'default-theme',
    sidebarBg: false,
	sidebarBgImg: 'bg1',
}

const UiProvider = ({ children }) => {
    const [pageTitle, setPageTitle] = useState(config.pageTitle)
    const [collapsed, setCollapsed] = useState(config.collapsed)
    const [toggled, setToggled] = useState(config.toggled)
    const [broken, setBroken] = useState(config.broken)
    const [mouseEnter, setMouseEnter] = useState(config.mouseEnter)

    const changeTitle = (title) => setPageTitle(title)
	const collapseSidebar = () => setCollapsed(!collapsed)
    const toggleSidebar = () => setToggled(!toggled)

    return (
        <UiContext.Provider value={{
            pageTitle,
            collapsed,
            toggled,
            broken,
            mouseEnter,

            changeTitle,
            collapseSidebar,
            toggleSidebar,
            setBroken,
            setMouseEnter,
        }}>
            {children}
        </UiContext.Provider>
    )
}

export const useUi = () => {
	const ctx = useContext(UiContext)

	if (!ctx) {
		throw new Error("useUi must be used within the UiProvider")
	}

	return ctx
}

export default UiProvider
