export class Currency {
    static NGN = new Currency('NGN')
    static USD = new Currency('USD')
    static UGX = new Currency('UGX')
    static KES = new Currency('KES')
    static ZAR = new Currency('ZAR')
    static ZMW = new Currency('ZMW')
    static EUR = new Currency('EUR')
    static GHS = new Currency('GHS')
    static TZS = new Currency('TZS')
    static RWF = new Currency('RWF')
    static XAF = new Currency('XAF')
    static XOF = new Currency('XOF')
    static EGP = new Currency('EGP')
    static GBP = new Currency('GBP')

    constructor(value) {
        this.value = value
    }
}
