import { useState } from 'react'
import { useGlobal } from '../../context/GlobalDataContext'
import Cookies from 'js-cookie'
import { sysMsgFlag } from '../../config/app'
import classNames from 'classnames'
import { Container } from './styles/Message'
import { TrialStatus } from './TrialStatus'
import { PaymentStatus } from './PaymentStatus'
import { ReactComponent as IconClose } from '../../assets/exports/close.svg'
import { SubscriptionStatus } from '../../enums'

export const SystemMessage = () => {
    const [show, setShow] = useState(!Cookies.get(sysMsgFlag))
    const {tenant: { subscription, invoice }} = useGlobal().data

    const handleShow = () => {
        setShow(false)
        Cookies.set(sysMsgFlag, true)
    }

    const close = <IconClose onClick={handleShow} />

    return (
        <Container className={classNames({hide: !show})}>
            {subscription.trial && <TrialStatus subscription={subscription} close={close} />}

            {SubscriptionStatus.paymentFailed(subscription.status) && <PaymentStatus invoice={invoice} close={close} />}
        </Container>
    )
}
