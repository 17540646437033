import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import { ReactComponent as Logo } from '../../assets/exports/logo.svg'
import { Link } from 'react-router-dom'
import { Text } from './Text'

const Container = styled(Link)`
    display: flex;
    align-items: center;
    gap: 8px;

    ${Text} {
        font-family: Poppins;
        font-size: 24px;
        font-weight: 600;
        color: #FFFFFF;
    }
`

const Brand = ({ className }) => (
    <Container to='/' className={className}>
        <Logo />
        <Text className='brand-text'>Realty Support</Text>
    </Container>
)

Brand.propTypes = { className: PropTypes.string }

export default Brand
