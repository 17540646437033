import styled from '@emotion/styled'
import bell from '../../assets/exports/User Interface/bell.svg'
import { Text } from '../Common/Text'

const Container = styled.div`
    position: relative;
    margin-right: 10px;

    ${Text} {
        padding: 3px 8px;

        font-size: 12px;
        color: #FFFFFF;
    
        position: absolute;
        left: 9px;
        top: -10px;
    
        background: #D4535C;
        border-radius: 100px;
    }
`

export const Notification = () => {
    return (
        <Container>
            <img src={bell} alt='icon' />
            <Text>0</Text>
        </Container>
    )
}
