export class PropertyStatus {
    static available = new PropertyStatus('Available')
    static 'property leased' = new PropertyStatus('Property Leased')
    static 'property sold' = new PropertyStatus('Property Sold')

    constructor(value) {
        this.value = value
    }

    static get(status) {
        return this[status].value
    }

    static isVacant(status) {
        return status.toLowerCase() === this.available.value.toLowerCase()
    }
}
