import { PostCheckout } from "../enums"

export const addCardLink = (pathname) => {
    const queries = new URLSearchParams({
        redirect: pathname,
        post_checkout: PostCheckout.PAYMENT_METHOD.value,
    })

    return `/billing/checkout?${queries.toString()}`
}
