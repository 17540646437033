export class PaymentStatus {
    static SUCCEED = new PaymentStatus('succeeded')
    static PROCESS = new PaymentStatus('processing')
    static PAYMENT_METHOD = new PaymentStatus('requires_payment_method')
    static ACTION = new PaymentStatus('requires_action')

    constructor(value) {
        this.value = value
    }
}
