import { createElement } from 'react'
import styled from '@emotion/styled'

const BaseIcon = (_ref) => {
    const color = _ref.color,
          _ref$pushRight = _ref.pushRight,
          pushRight = _ref$pushRight === undefined ? true : _ref$pushRight,
          children = _ref.children

    return createElement('svg', {
            xmlns: 'http://www.w3.org/2000/svg',
            width: '20',
            height: '20',
            viewBox: '0 0 24 24',
            fill: 'none',
            stroke: color,
            strokeWidth: '2',
            strokeLinecap: 'round',
            strokeLinejoin: 'round',
            style: { marginRight: pushRight ? '20px' : '0', minWidth: 20 }
        },
        children
    )
}

const config = {
    info: {
        color: '#FFFFFF',
        bgColor: '#2E9AFE'
    },
    success: {
        color: '#FFFFFF',
        bgColor: '#31B404'
    },
    error: {
        color: '#FFFFFF',
        bgColor: '#FF0040'
    },
}

const InfoIcon = ({type}) => createElement(
    BaseIcon,
    { color: config[type].color },
    createElement('circle', { cx: '12', cy: '12', r: '10' }),
    createElement('line', { x1: '12', y1: '16', x2: '12', y2: '12' }),
    createElement('line', { x1: '12', y1: '8', x2: '12', y2: '8' })    
)
  
const SuccessIcon = ({type}) => createElement(
    BaseIcon,
    { color: config[type].color },
    createElement('path', { d: 'M22 11.08V12a10 10 0 1 1-5.93-9.14' }),
    createElement('polyline', { points: '22 4 12 14.01 9 11.01' })
)
  
const ErrorIcon = ({type}) => createElement(
    BaseIcon,
    { color: config[type].color },
    createElement('circle', { cx: '12', cy: '12', r: '10' }),
    createElement('line', { x1: '12', y1: '8', x2: '12', y2: '12' }),
    createElement('line', { x1: '12', y1: '16', x2: '12', y2: '16' })
)
  
const CloseIcon = () => createElement(
    BaseIcon,
    { color: '#E3F9F2', pushRight: false },
    createElement('line', { x1: '18', y1: '8', x2: '8', y2: '18' }),
    createElement('line', { x1: '8', y1: '8', x2: '18', y2: '18' })
)

const Message = styled.span(
    { flex: 2 },
    props => ({
        color: props.options.custom ? '#FFFFFF' : config[props.options.type].color,
        fontSize: '16px',
    })
)

const alertStyle = {
    backgroundColor: '#151515',
    color: 'white',
    padding: '5px 10px',
    borderRadius: '4px',
    display: 'flex',
    justifyContent: 'space-between',
    boxShadow: '0px 2px 2px 2px rgba(0, 0, 0, 0.03)',
    width: '100%',
    maxWidth: '420px',
    margin: '20px 5px',
}

const Button = styled.button({
    marginLeft: '15px',
    padding: 0,
    border: 'none',
    backgroundColor: 'transparent',
    cursor: 'pointer',
    color: '#FFFFFF'
})

const icons = {info: InfoIcon, success: SuccessIcon, error: ErrorIcon}

// the style contains only the margin given as offset
// options contains all alert given options
// message is the alert message
// close is a function that closes the alert
export const AlertTemplate = ({ style, options, message, close }) => {
    const styles = {
        ...style,
        ...alertStyle,
        alignItems: options.custom ? 'start' : 'center',
        backgroundColor: config[options.type].bgColor,
    }

    return (
        <div css={styles}>
            {! options.custom && createElement(icons[options.type], {type: options.type})}
            
            <Message options={options}>{message}</Message>

            <Button onClick={close}>
                {createElement(CloseIcon, null)}
            </Button>
        </div>
    )
}
