import styled from '@emotion/styled'
import Button from '../Button/Button'
import { Text } from '../Text'

export const Main = styled.div({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',

    height: '100%',
    background: 'url(/../../../../assets/mainimages/city-bg1.svg) no-repeat center bottom -220px',
    backgroundSize: 'cover',
})

export const Message = styled(Text)({
    fontSize: '20px',
    fontWeight: 500,
    color: 'grey',
})

export const Reason = styled(Message)({
    fontSize: '17px',
    fontWeight: 400,
    opacity: .8,
    padding: '5px 0 20px',
})

export const Btn = styled(Button)({
    fontWeight: 500,
    color: '#7e8ea6',
})
