import { RequestTicket, request } from '../utils/api'

/**
 * CRUD requests that return a prepared fetch. Promise is handled in DataHandler context.
 *
 * @see HandlerDataProvider
 */

/**
 * Simple delete request with no data or redirects.
 *
 * @see RequestTicket
 * @see useAuth
 * @param  {} url url string of backend resource (/api/snippets or /api/collections)
 * @param  {} token access token stored in context memory for request Authorization header
 */
export function deleteRequest({ url, token, }) {
	const config = RequestTicket({
		method: 'delete',
		url,
		token,
	})

	return request(config)
}

/**
 * Put request.
 *
 * @see RequestTicket
 * @see useAuth
 * @param  {} url url string of backend resource (/api/snippets or /api/collections)
 * @param  {} token access token stored in context memory for request Authorization header
 * @param  {} data put request data (edit snippet)
 */
export function putRequest({ url, token, data, headers = {} }) {
	const config = RequestTicket({
		method: 'put',
		url,
		token,
		data,
		headers
	})

	return request(config)
}

export function putRequestWithFiles({ url, token, data, }) {
	data._method = 'PUT'

	const config = RequestTicket({
		method: 'post',
		url,
		token,
		data,
		headers: {
			'Content-Type': 'multipart/form-data'
		}
	})

	return request(config)
}

/**
 * Post request handler (General).
 *
 * @see RequestTicket
 * @param  {} url url string of backend resource (/api/snippets or /api/collections)
 * @param  {} token access token stored in context memory for request Authorization header
 * @param  {} data data of post request (new Snippets, new Collections)
 */
export function postRequest({ url, token, data, headers = {} }) {
	const config = RequestTicket({
		method: 'post',
		url,
		token,
		data,
		headers
	})

	return request(config)
}

export function postFormRequest({ url, token, data, headers = {} }) {
	headers['Content-Type'] = 'multipart/form-data'

	return postRequest({url, token, data, headers})
}

/**
 * Get request handler (General).
 *
 * @see RequestTicket
 * @see useAuth
 * @param  {} url url string of backend resource (/api/snippets or /api/collections)
 * @param  {} token access token stored in context memory for request Authorization header
 */
export function getRequest({ url, token, data, signal }) {
	const config = RequestTicket({
		method: 'get',
		url,
		token: token || '',
		data,
		signal
	})

	return request(config)
}

function hasObject(data) {
	for (let key in data) {
		if (typeof(data[key]) === 'object') {
			return true
		}
	}

	return false
}