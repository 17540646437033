import styled from '@emotion/styled'
import { Text } from '../../Common/Text'
import { Close } from '../../Common/Close'

export const Container = styled.div`
    &.hide {
        display: none;
    }
`

export const CloseBtn = styled(Close)`
    background: none;
    svg path {
        fill: #FFFFFF;
    }
`

const Message = styled.div`
    display: flex;
    padding: 16px;
    justify-content: space-between;
    gap: 20px;

    .wrapper {
        display: flex;
        width: 100%;
        align-items: center;
        gap: 10px;

        ${Text} {
            color: #FFFFFF;

            span {
                font-weight: 700;
            }
        }
    }

    svg {
        cursor: pointer;

        path {
            fill: #FFFFFF;
        }
    }
`

export const PaymentFailed = styled(Message)`
    background: #FF0040;

    .wrapper {
        flex-direction: row;
    }
`

export const Trial = styled(Message)`
    align-items: center;

    .wrapper {
        justify-content: space-between;
    }
`
