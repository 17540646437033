import { BuildingType } from "./BuildingType"

export class PropertyType {
    static Hall = new PropertyType('Auditorium')
    static Office = new PropertyType(BuildingType.Office.value)
    static Home = new PropertyType(BuildingType.Home.value)
    static Field = new PropertyType('Land')

    constructor(value) {
        this.value = value
    }

    static isHall(type) {
        return type === this.Hall.value
    }

    static isOffice(type) {
        return type === this.Office.value
    }

    static isHome(type) {
        return type === this.Home.value
    }

    static isLand(type) {
        return type === this.Field.value
    }

    static isBuilding(type) {
        return (type === this.Home.value || type === this.Office.value)
    }
}
