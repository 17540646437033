import PropTypes from 'prop-types'
import { useLocation } from 'react-router-dom'
import { Text } from '@common/Text'
import Button from '../Common/Button/Button'
import { diff } from '../../utils/date'
import { Trial } from './styles/Message'
import { addCardLink } from '../../helpers/link'

const Message = ({period, remaining}) => {
    const append = `Your ${period} ${(period > 1) ? 'days' : 'day'} free trial`

    return (
        <>
            {(remaining < 1) && <Text>{append} has <span>expired</span></Text>}

            {(remaining === 1) && <Text>{append} ends <span>today</span></Text>}
                
            {(remaining > 1) && <Text>{append} ends in <span>{remaining} days</span></Text>}
        </>
    )
}

export const TrialStatus = ({subscription: { date, validity, plan, creditCard }, close}) => {
    const {pathname} = useLocation()
    const remainingDays = Math.round(diff(new Date, validity) + .5)

    if (remainingDays > 3) {
        return null
    }

    return (
        <Trial css={{background: (remainingDays < 1) ? '#FF0040' : '#00a569'}}>
            <div className='wrapper'>
                <Message period={diff(date, validity)} remaining={remainingDays} />

                {!plan && <Button size={38} type='fade' elementType='a' to='/billing/packages'>Subscribe now</Button>}
                
                {(plan && !creditCard) && <Button size={38} type='fade' elementType='a' to={addCardLink(pathname)}>Add a card to avoid interruption</Button>}
            </div>

            {close}
        </Trial>              
    )
}

TrialStatus.propTypes = {
    subscription: PropTypes.object.isRequired,
    close: PropTypes.object.isRequired
}
