import axios from 'axios'
import { RequestTicket } from '../utils/api'

export const activate = (code, data) => {
    return axios(RequestTicket({
        method: 'post',
        url: `verify-user/${code}`,
        data
    }))
}

export const login = data => {
    return axios(RequestTicket({
        method: 'post',
        url: 'login',
        data
    }))
}

export const logout = token => axios(RequestTicket({method: 'post', url: 'logout', token}))
