import styled from '@emotion/styled'
import PropTypes from 'prop-types'
import { ReactComponent as IconPlus } from '../../../assets/exports/User Interface/Plus.svg'
import { Link } from 'react-router-dom'

const attributes = property => {
    let properties
    
    switch (property) {
        case '38':
            properties = { height: '38px', pad: '12px', iconSize: 16, fontSize: '13px' }
            break
    
        case '44':
            properties = { height: '44px', pad: '12px', iconSize: 18, fontSize: '14px' }
            break
                
        case '48':
            properties = { height: '48px', pad: '12px', iconSize: 19, fontSize: '16px' }
            break

        default:
            properties = { height: '58px', pad: '20px', iconSize: 20, fontSize: '18px' }
            break
    }

    return properties
}

const transparent = ['ghost-dark', 'ghost-lite', 'outline']

const Button = styled.button`
    display: flex;
    height: ${props => attributes(props.property).height };
    justify-content: center;
    align-items: center;
    padding: ${props => attributes(props.property).pad };

    white-space: nowrap;

    position: relative;

    background: #000000;
    color: #FFFFFF;
    border-radius: 8px;
    border: none;

    // width: ${props => attributes(props.property).iconSize };
    // height: ${props => attributes(props.property).iconSize };
    svg path {
        fill: ${props => props.iconcolor || '#FFFFFF'};
    }

    &:disabled {
        background: ${props => transparent.includes(props.type) ? 'none' : '#09414F' };
        opacity: ${props => transparent.includes(props.type) ? 1 : 0.3 };
        cursor: auto;
        
        .btn-text, svg {
            opacity: ${props => transparent.includes(props.type) ? 0.3 : 1 };
        }
    }
`

const ButtonLink = Button.withComponent(Link)

const Text = styled.span`
    display: flex;
    gap: 4px;
    font-family: 'Inter';
    font-size: ${props => attributes(props.property).fontSize };
`

export default function Master({elementType, iconRight, rightIcon: RightIcon, iconLeft, leftIcon: LeftIcon, children, ...props}) {
    const Btn = (elementType === 'button') ? Button : ButtonLink
    const gap = (children === undefined) ? 0 : '4px'
    const cursor = (props.onClick === undefined) ? 'auto' : 'pointer'

    return (
        <Btn {...props} state={props.state} css={{gap, cursor}}>
            {iconRight && <RightIcon />}

            <Text property={props.property} className='btn-text'>{children}</Text>

            {iconLeft && <LeftIcon />}
        </Btn>
    )
}

Master.defaultProps = {
    iconRight: false,
    rightIcon: IconPlus,
    iconLeft: false,
    leftIcon: IconPlus,
    property: 'Default',
    disabled: false,
}

Master.propTypes = {
    elementType: PropTypes.oneOf(['button', 'a']),
    iconRight: PropTypes.bool,
    rightIcon: PropTypes.object,
    iconLeft: PropTypes.bool,
    leftIcon: PropTypes.object,
	property: PropTypes.oneOf(['38', '44', '48', '58', 'Default']),    
    disabled: PropTypes.bool,
    children: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
    className: PropTypes.string,
}
