import { createContext, useContext, useState } from 'react'

const MetaContext = createContext()

const MetaProvider = ({ children }) => {
    const [pageTitle, setPageTitle] = useState('Home')

	const changeTitle = (title) => setPageTitle(title)    

	return (
		<MetaContext.Provider value={{ pageTitle, changeTitle }}>
			{children}
		</MetaContext.Provider>
	)
}

export const useMeta = () => {
	const ctx = useContext(MetaContext)

	if (!ctx) {
		throw new Error("useMeta must be used within the MetaProvider")
	}

	return ctx
}

export default MetaProvider