/**
 * Utility object with keys to functions pertaining to local storage management.
 *
 * @see AuthProvider
 */
import Cookies from 'js-cookie'
import { parseJwt } from './string'
import { env } from './env'

const inFifteenMinutes = new Date(new Date().getTime() + 15 * 60 * 1000)

export const cookieApi = Cookies.withAttributes({
	secure: env('SECURE_COOKIE_TRANSMISSION', true),
	expires: inFifteenMinutes,
}).withConverter({
	read: function (value, name) {
		try {
			return JSON.parse(value)
		} catch (error) {
			// Fall back to default for all other cookies
			return Cookies.converter.read(value, name)
		}
	},

	write: function (value) {
		if ((typeof(value) === 'object')) {
			return JSON.stringify(value)
		}

		return value
	}
})

const clearCookies = () => {
	Object.keys(cookieApi.get()).forEach(function(cookieName) {
		const neededAttributes = {
			// Here you pass the same attributes that were used when the cookie was created
			// and are required when removing the cookie
		}
		cookieApi.remove(cookieName, neededAttributes)
	})
}

export const storage = {
	setUser: (user) => {
		const {refreshToken, ...data} = user
		
		localStorage.setItem('user', JSON.stringify(data))
		
		cookieApi.set('token', refreshToken, {
			expires: new Date(parseJwt(refreshToken).exp * 1000),
		})
	},
	
	getUser: () => {
		const user = JSON.parse(localStorage.getItem('user'))
		return user ? {...user, refreshToken: cookieApi.get('token')} : null
	},

	clear: () => {
		localStorage.clear()
		sessionStorage.clear()
		clearCookies()
	},
	
	setLogoutEvent: () => window.localStorage.setItem( 'app_logout', JSON.stringify(Date.now()) ),

	setGlobalData: (data, token) => {
		const decodedJwt = parseJwt(token)
		const options = {
			expires: new Date(decodedJwt.exp * 1000),
		}
		
		cookieApi.set('data', data, options)
	},
}
