import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import { Text } from './Text'
import { initials } from '../../utils/string'

const Image = styled.div`
    width: 64px;
    height: 64px;
    border-radius: 64px;
    background: 50% / cover no-repeat url(${props => props.url}), #F0F5F3;
`

const NoImage = styled(Image)`
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 100px;
    background: #E6F5EF;

    ${Text} {
        font-size: 22px;
        font-weight: 500;
        color: #1AAB7A;
    }
`

export const Picture = ({name, image, className}) => {
    return image
        ? <Image className={className} url={image?.url} /> 
        : (
            <NoImage className={className}>
                {name && <Text>{initials(name)}</Text>}
            </NoImage>
        )
}

Picture.propTypes = {
    name: PropTypes.string,
	image: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
    className: PropTypes.string,
}
