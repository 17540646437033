export const breakpoints = [576, 768, 992, 1200, 1600, 1920]

export const sm = breakpoints[0]

export const md = breakpoints[1]

export const lg = breakpoints[2]

export const xl = breakpoints[3]

export const xxl = breakpoints[4]

export const xxxl = breakpoints[5]
