import PropTypes from 'prop-types'
import { PaymentStatus as PaymentStatusEnum } from '../../enums'
import { Text } from '@common/Text'
import { PaymentFailed } from './styles/Message'

export const PaymentStatus = ({invoice, close}) => { 
    const failure = () => {
        if (invoice.payment.status.value === PaymentStatusEnum.PAYMENT_METHOD.value) {
            return 'Payment method required. Add your credit card to regain full access.'
        }

        return 'We were unable to charge your credit card. Update your payment details to regain full access.'
    }
    
    if (invoice.payment.status.value === PaymentStatusEnum.PROCESS.value) return

    return (
        <PaymentFailed>
            <div className='wrapper'>
                <Text>Payment failed.</Text>
                <Text size='14px'>{failure()}</Text>
            </div>

            {close}
        </PaymentFailed>
    )
}

PaymentStatus.propTypes = {
    invoice: PropTypes.object.isRequired,
    close: PropTypes.object.isRequired
}
