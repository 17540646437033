
export function formatDate(date, options = {}, locale = 'en-US') {
     const defaultOptions = { day: '2-digit', month: 'short', year: 'numeric' }
     const formattedDate = new Date(date).toLocaleDateString(locale, { ...defaultOptions, ...options })

     // Split the formatted date into day, month, and year parts
     const [month, day, year] = formattedDate.split(' ')

     // Convert the month abbreviation to uppercase
     const capitalizedMonth = month.toUpperCase()

     // Return the formatted date with uppercase month abbreviation and desired format
     return `${day.replace(',', '')} ${month}, ${year}`
}

export const time = (dateString, locale = 'en-NG', options = {}) => {
     const defaultOptions = { hour: 'numeric', minute: 'numeric', hour12: true }
     
     return new Date(dateString).toLocaleTimeString(locale, { ...defaultOptions, ...options })
}

export function timeSince(date) {
     if (typeof date !== 'object') {
          date = new Date(date)
     }
   
     const seconds = Math.floor((new Date() - date) / 1000)

     let intervalType
     let interval = Math.floor(seconds / 31536000)

     if (interval >= 1) {
          intervalType = 'year'
     } else {
          interval = Math.floor(seconds / 2592000)
          
          if (interval >= 1) {
               intervalType = 'month'
          } else {
               interval = Math.floor(seconds / 86400)
               
               if (interval >= 1) {
                    intervalType = 'day'
               } else {
                    interval = Math.floor(seconds / 3600)
                    
                    if (interval >= 1) {
                         intervalType = "hour"
                    } else {
                         interval = Math.floor(seconds / 60)
                         
                         if (interval >= 1) {
                              intervalType = "minute"
                         } else {
                              interval = seconds
                              intervalType = "second"
                         }
                    }
               }
          }
     }
     
     if (interval > 1 || interval === 0) {
          intervalType += 's'
     }
     
     return interval + ' ' + intervalType
}

export const day = (dateString) => new Date(dateString).getDate()

export const month = (dateString) => {
     const months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"]
     const date = new Date(dateString)

     return months[date.getMonth()]
}

export function diff(startDate, endDate) {
     const start = new Date(startDate)
     const end = new Date(endDate)

     // Calculate the time difference of two dates
     const timestamp =  end.getTime() -  start.getTime()

     // Returns the number of days between two dates
     return timestamp / (1000 * 3600 * 24)
}

/**
* Format Time.
* @param {string} dateString 
* @returns {string}
*/
export function formatAMPM(dateString) {
     const date = new Date(dateString)

     let  hours = date.getHours(),
          minutes = date.getMinutes(),
          ampm = hours >= 12 ? 'PM' : 'AM'

     hours = hours % 12
     hours = hours ? hours : 12 // the hour '0' should be '12'
     minutes = minutes < 10 ? '0' + minutes : minutes
     
     const strTime = hours + ':' + minutes + ' ' + ampm

     return strTime
}

// toISOString() - returns date string in UTC
export const toString = (date) => date.toLocaleDateString().split('/').reverse().join('-')
