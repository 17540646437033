import { createContext, useContext, useState } from 'react'
import { cookieApi } from '../utils/storage'

const GlobalContext = createContext()

const persistData = cookieApi.get('data')

const GlobalProvider = ({ children }) => {
	const [loading, setLoading] = useState(false)
    const [data, setData] = useState(persistData)
    const [error, setError] = useState(false)

	return (
		<GlobalContext.Provider value={{
			loading,
            data,
			error,
			setLoading,
            setData,
			setError
        }}>
			{children}
		</GlobalContext.Provider>
	)
}

export const useGlobal = () => {
	const ctx = useContext(GlobalContext)

	if (!ctx) {
		throw new Error("useGlobal must be used within the GlobalProvider")
	}

	return ctx
}

export default GlobalProvider
