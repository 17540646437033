import dashboard from '../assets/exports/Side/Bottom/Dashboard.svg'
import property from '../assets/exports/Side/Bottom/Property.svg'
import contract from '../assets/exports/Side/Bottom/Contract.svg'
import client from '../assets/exports/Side/Bottom/Clients.svg'
import communication from '../assets/exports/Side/Bottom/Communication.svg'
import maintenance from '../assets/exports/Side/Bottom/Maintainance.svg'
import team from '../assets/exports/Side/Bottom/Team.svg'
import billing from '../assets/exports/Side/Bottom/credit_card.svg'
import settings from '../assets/exports/Side/Bottom/settings/Default.svg'

export const menu = [
    {
        header: 'Overview',
        items: [
            {
                id: 1,
                path: '/',
                icon: dashboard,
                label: 'Dashboard'
            },
        
            {
                id: 2,
                path: '#',
                icon: property,
                label: 'Properties',
                children: [
                    {
                        id: 1,
                        path: '/houses',
                        label: 'Homes'
                    },
        
                    {
                        id: 2,
                        path: '/land',
                        label: 'Land'
                    },
        
                    {
                        id: 3,
                        path: '/commercials',
                        label: 'Commercial Building'
                    },
        
                    {
                        id: 4,
                        path: '/auditoriums',
                        label: 'Auditorium'
                    },
                ]
            },
            
            {
                id: 3,
                path: '/contracts',
                icon: contract,
                label: 'Contracts'
            },
            
            {
                id: 4,
                path: '/clients',
                icon: client,
                label: 'Clients'
            },
            
            {
                id: 5,
                path: '/communication',
                icon: communication,
                label: 'Communication'
            },
            
            {
                id: 6,
                path: '/maintenance',
                icon: maintenance,
                label: 'Maintenance'
            },            
        ]
    },

    {
        header: 'Account',
        items: [
            {
                id: 1,
                path: '/team',
                icon: team,
                label: 'Team'
            },
        
            {
                id: 2,
                path: '/billing',
                icon: billing,
                label: 'Billing'
            },            
        
            {
                id: 3,
                path: '/settings',
                icon: settings,
                label: 'Settings'
            },            
        ]
    },    
]
