import { Main, Message, Reason } from "./styles/Error"

const Error = ({error}) => (
    <Main>
        <Message>Oops! Something went wrong</Message>
        <Reason>{error}</Reason>
    </Main>    
)

export default Error
