import { useEffect } from 'react'
import { useMeta } from '../context/MetaContext'

export const useTitle = (title, docTitle = false) => {
	const {changeTitle} = useMeta()
	
	useEffect( () => {
		changeTitle(title)

		if (docTitle) {
			document.title = `${title} - Realty Support`
		}
    })
}
