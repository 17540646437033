import axios from "axios"
import { app } from "../config"
import { storage } from "./storage"

const instance = axios.create({
	baseURL: app.api,
	timeout: 1000,
	headers: {'Content-Type': 'application/json'},
})

export const API = app.api
export const request = config => axios(config)

const TOKEN_NOT_FOUND = 'refresh token does not exist'

const refreshToken = async() => {
	const token = storage.getUser()?.refreshToken

	if (!token)
		throw new Error(TOKEN_NOT_FOUND)
	
	const config = RequestTicket({ method: 'post', url: 'refresh-token', data: {token} })
	const response = await request(config)
	
	return response.data
}

const withAuth = (token, headers = {}) => {
	if (token) {
		headers.Authorization = `Bearer ${token}`
	} else {
		const user = storage.getUser()
		
		if (user) {
			headers.Authorization = `Bearer ${user.accessToken}`
		}
	}

	return headers
}

const isUnauthorizedError = (response) => response.status === 401
const isExpiredSessionError = (response) => (isUnauthorizedError(response) && response.data.search('Session has expired') > 0 && !response.config._retry)

/**
 * Axios API Request Config
 */
export const RequestTicket = ({ method, token, url, data, headers = {}, signal }) => {
	const _headers = {
		'Content-Type': 'application/json',
		...headers
	}
	
	const def = {
		headers: withAuth(token, _headers),
		baseURL: API,
		timeout: 30000,
		signal
	}

	if (method === 'post') {
		return {
			...def,
			...{
				url,
				method: 'POST',
				data,
			}
		}
	}

	if (method === 'put') {
		return {
			...def,
			...{
				url,
				method: 'PUT',
				data,	
			}	
		}
	}
 
	if (method === 'delete') {
		return {
			...def,
			...{
				url,
				method: 'DELETE',
			}
		}
	}
	
	return {
		...def,
		...{
			url,
			method: 'GET',
			params: data,
		}
	}
}

let refreshing = undefined

/**
 * Request Interceptor
 */
axios.interceptors.request.use(
	config => {
		const user = storage.getUser()
		
		if (!config.headers.Authorization && user) {
			config.headers.Authorization = `Bearer ${user.accessToken}`
		}

		return config
	},
	error => Promise.reject(error)
)

/**
 * Response Interceptor
 */
axios.interceptors.response.use(
	result => result,
	async err => {
		if (axios.isCancel(err)) {
			return Promise.reject(err)
		} else {
			const originalConfig = err.config
		
			if ( err.response && isExpiredSessionError(err.response) ) {
				originalConfig._retry = true
				
				try {
					// the trick here, that `refreshing` is global, e.g. 2 expired requests will get the same function pointer and await same function.
					if (!refreshing)
						refreshing = refreshToken()

					const auth = await refreshing

					// update user session
					storage.setUser({...storage.getUser(), ...auth})

					originalConfig.headers.Authorization = `Bearer ${auth.accessToken}`

					// retry original request
					try {
						return await request(originalConfig)
					} catch (innerError) {
						// if original request failed with 401 again - it means server returned not valid token for refresh request
						if (isUnauthorizedError(innerError?.response)) {
							logout()
						} 

						throw innerError
					}

				} catch (error) {
					if (error.message === TOKEN_NOT_FOUND) {
						logout()
					}

					console.log(error)
				} finally {
					refreshing = undefined
				}
			}

			return Promise.reject(err)
		}
	}
)

function logout() {
	// destroy user session
	storage.clearUser()
	window.location = `${window.location.origin}/login?redirect=${window.location.href}`
}
