export class PaymentMethod {
    static BankTransfer = new PaymentMethod('Bank Transfer')
    static Cash = new PaymentMethod('Cash')
    static Check = new PaymentMethod('Check')
    static Flutterwave = new PaymentMethod('Flutterwave')
    static Paystack = new PaymentMethod('Paystack')

    constructor(value) {
        this.value = value
    }
}
