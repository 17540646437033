export class Priority {
    static Critical = new Priority('Critical')
    static High = new Priority('High')
    static Medium = new Priority('Medium')
    static Low = new Priority('Low')

    constructor(value) {
        this.value = value
    }
}
