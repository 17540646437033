import styled from '@emotion/styled'
import { PropagateLoader } from 'react-spinners'

const StyledLoader = styled(PropagateLoader)`
    top: 50%;
    left: 50%;
`

const Loader = ({loading = true}) => <StyledLoader color='#1AAB7A' loading={loading} />

export default Loader
