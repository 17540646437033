// /** @jsxImportSource @emotion/react */
import { Link } from 'react-router-dom'
import styled from '@emotion/styled'
import { layout } from '../../../config/styles'
import { Text } from '../../Common/Text'
import DropdownComp from '../../Common/Dropdown'

export const Column = styled.div`
    display: flex;
    height: calc(${layout.main.header} - 1px);
    justify-content: flex-end;
    align-items: center;
    gap: 24px;
`

export const Account = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 42px;
    height: 42px;
    border-radius: 100px;
    background: #193B40;

    svg path {
        fill: #fff;
    }

    img {
        width: 100%;
        height: 100%;        
    }
`

export const Dropdown = styled(DropdownComp)`
    .dropdown {
        width: 350px;
        right: 0;
    }
`

export const DropdownHeader = styled.div`
    display: flex;
    padding: 32px 24px;
    gap: 16px;
    
    background: #FFFFFF;
    border-bottom: 1px solid #E3E3E3;

    div.group {
        display: flex;
        flex-direction: column;
        gap: 8px;        
    }
`

export const Icon = styled.div`
    display: flex;
    justify-content: center;
    width: 24px;

    svg path {
        fill: #193B40;
    }
`

export const DropdownItem = styled(Link)`
    display: flex;
    align-items: center;
    padding: 0px 12px;
    gap: 16px;

    height: 54px;

    &:hover {
        background: #1AAB7A;
        border-radius: 6px;

        ${Icon} {
            path {
                fill: #FFFFFF;
            }
        }

        ${Text} {
            color: #FFFFFF;
        }        
    }

    ${Text} {
        font-weight: 500;
    }
`
