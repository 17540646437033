import { positions, transitions } from '@blaumaus/react-alert'
import { AlertTemplate } from '../components/Common/AlertTemplate'

const alert = {
    template: AlertTemplate,
    options: {
        position: positions.BOTTOM_CENTER,
        timeout: 5000,
        offset: '5px', // margin of alert
        transition: transitions.SCALE
        //template: AlertTemplate --- Commented since we're setting it independently on the component        
    },
}

export default alert
