import PropTypes from 'prop-types'
import { withTitle } from '@components/hoc'
import { useTitle } from '@/hooks'
import { Sidebar } from 'react-pro-sidebar'
import SidebarMenu from './SidebarMenu'
import { menu } from '../../config'
import MainHeader from './Header'
import { layout } from '../../config/styles'
import Brand from '../Common/Brand'
import { md } from '../../config/breakpoints'
import { useUi } from '../../context/UIContext'
import { Container, Footer, Header, Main, MenuBox, MenuSection, Resizer, StyledText } from './styles/Layout'
import { ReactComponent as Collapse } from '../../assets/exports/Arrows/left arrow.svg'
import { ReactComponent as Expand } from '../../assets/exports/Arrows/diagonal resize.svg'
import { SystemMessage } from './SystemMessage'

const Layout = ({ children, title = 'Home', info }) => {
    useTitle(title, true)

    const { collapsed, toggled, mouseEnter, collapseSidebar, toggleSidebar, setBroken, setMouseEnter } = useUi()

    const sidebarHover = (e) => {
        if (collapsed) {
            setMouseEnter(e.type === 'mouseenter')
        }
    }

    return (
        <Container>            
            <Sidebar
                width={layout.sidebar.width}
                backgroundColor={layout.sidebar.bgColor}
                collapsed={collapsed && !mouseEnter}
                toggled={toggled}
                onBackdropClick={toggleSidebar}
                breakPoint='md'
                customBreakPoint={`${md}px`}
                onBreakPoint={setBroken}
                onMouseEnter={sidebarHover}
                onMouseLeave={sidebarHover}
            >
                <Header>
                    <Brand />
                </Header>
                
                <MenuBox>
                    {menu.map(({header, items}, index) => {
                        return (
                            <MenuSection key={index}>
                                {header && <StyledText>{header}</StyledText>}
                                <SidebarMenu items={items} />
                            </MenuSection>                                    
                        )            
                    })}
                </MenuBox>

                <Footer>
                    <Resizer onClick={() => collapseSidebar()}>
                        {collapsed && <Expand width={16} height={16} />}
                        {!collapsed && <Collapse />}
                    </Resizer>
                </Footer>
            </Sidebar>
            
            <Main>
                <SystemMessage />
                <MainHeader />
                {children}             
            </Main>
        </Container>
      );    
}

Layout.propTypes = {
    children: PropTypes.node.isRequired,
    title: PropTypes.string,
    info: PropTypes.string,
}

export default withTitle(Layout)
