
export const typography = {
    fontFamilySansSerif: "'Inter', sans-serif",
    fontSizeBase: '0.9rem',
    lineHeightBase: 1.6,
}

export const colors = {
    bodyBg: '#f8fafc',
    white: '#fff',
    black: '#000',
    blue: '#3490dc',
    indigo: '#6574cd',
    purple: '#9561e2',
    pink: '#f66d9b',
    red: '#e3342f',
    orange: '#f6993f',
    yellow: '#ffed4a',
    green: '#38c172',
    teal: '#4dc0b5',
    cyan: '#6cb2eb',

    // Stark Contrast
    slate: '#262626',
    secondhandGray: '#3f3f3f',
    whitish: '#f5f5f5',
    lightGray: '#dcdcdc',    
}

export const transition = {
    duration: '.3s',
}

export const layout = {
    sidebar: {
        width: '343px',
        bgColor: '#0B2B33',
        headerHeight: '109px',
        footerHeight: '94px',
    },

    main: {
        header: '80px',
        actionHeader: '90px',
    }
}
