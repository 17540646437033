export class SubscriptionStatus {
    static TRIAL = new SubscriptionStatus('trialling')
    static ACTIVE = new SubscriptionStatus('active')
    static INCOMPLETE = new SubscriptionStatus('incomplete')
    static INITIAL_PAYMENT_FAILED = new SubscriptionStatus('incomplete_expired')
    static CANCELED = new SubscriptionStatus('canceled')
    static UNPAID = new SubscriptionStatus('unpaid')
    static PAUSED = new SubscriptionStatus('paused')

    constructor(value) {
        this.value = value
    }

    static paymentFailed(status) {
        return (
            status.value === SubscriptionStatus.INCOMPLETE.value ||
            status.value === SubscriptionStatus.INITIAL_PAYMENT_FAILED.value
        )
    }
}
